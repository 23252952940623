<template>
  <h2><slot /></h2>
</template>

<script>
export default {}
</script>

<style scoped>
h2 {
  @apply font-bold;
  @apply uppercase;
  @apply text-lg;
}
</style>
